import firebase from "src/firebase";
import { SnackbarProvider } from "notistack";
import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { messages } from "src/messages/messages";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { Script } from "gatsby";
import { getFirestore } from "firebase/firestore";

if (window.location.hostname === "localhost") {
  console.log(
    "localhost detected! using emulated firestore running on port 8080 and emulated auth running on port 9099..."
  );
  const db = getFirestore(firebase);
  if (!db._settingsFrozen) {
    const storage = getStorage(firebase);
    connectStorageEmulator(storage, "localhost", 9199);
    try {
      firebase.firestore().useEmulator("localhost", 8080);
      firebase.auth().useEmulator("http://localhost:9099/");
      const functions = getFunctions(firebase);
      connectFunctionsEmulator(functions, "localhost", 5001);
    } catch (e) {
      console.log("Error", e);
    }
  }
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>
    <IntlProvider locale="en" messages={messages}>
      <SnackbarProvider>{element}</SnackbarProvider>
    </IntlProvider>
  </PrismicPreviewProvider>
);

const PageWrapper = ({ element }) => {
  useEffect(() => {
    if (!firebase) {
      console.log("no firebase");
      return;
    }
  }, []);
  return element;
};

export const wrapPageElement = ({ element }) => (
  <>
    <PageWrapper element={element} />
    <Script
      key="userGuiding"
      dangerouslySetInnerHTML={{
        __html: `
    (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','515983749ID');
    `,
      }}
    />
    ,
  </>
);
