export const messages = {
  "Sign up to become a Drone Operator": "Sign up to become a Drone Operator",
  "Already have an account?": "Already have an account? ",
  "Log in here": "Log in here",
  "Create Account": "Create Account",
  "Sign in to GLOBHE": "Sign in to GLOBHE",
  "No account?": "No account? ",
  "Sign up here": "Sign up here",
  "Reset Password": "Reset Password",
  "Remember me": "Remember me",
  "Sign In": "Sign In",
  Missions: "Missions",
  "My Profile": "My Profile",
  "Sign Out": "Sign Out",
  "Thanks for joining the movement!": "Thanks for joining the movement!",
  "We'll review your application and hopefully add you to our roster of Drone Operators very soon!":
    "We'll review your application and hopefully add you to our roster of Drone Operators very soon!",
  "We’ve sent you a confirmation email.":
    "We’ve sent you a confirmation email.",
  "Haven’t received an email?": "Haven’t received an email? ",
  "Click here to resend": "Click here to resend",
  "I’m insured": "I’m insured",
  "I’ve got permission to fly": "I’ve got permission to fly",
  "I agree to the terms & conditions":
    "I agree to the <a href='https://www.globhe.com/terms-and-conditions' target='_blank'>terms & conditions</a>",
  "I’ve got a registered business": "I’ve got a registered business",
  "Edit Your Profile": "Edit Your Profile",
  "Reset password": "Reset password",
  "Back to Login": "Back to Login",
  "We've sent you an email with a link to reset your password":
    "We've sent you an email with a link to reset your password",
  "Place your order below & we'll get back to you with an order confirmation (including pricing & time estimates) for you to approve before we send a drone pilot out to capture your data.":
    "Place your order below & we'll get back to you with an order confirmation (including pricing & time estimates) for you to approve before we send a drone pilot out to capture your data.",
  "Request Drone Data": "Request Drone Data",
  "What kind of data do you need?": "What kind of data do you need?",
  Submit: "Submit",
  "2D Map": "2D Map",
  "3D Map": "3D Map",
  "Elevation Map": "Elevation Map",
  "AI Map": "AI Map",
  "Thermal Map": "Thermal Map",
  "Raw data/photos": "Raw data/photos",
  "Organization/Company Name": "Organization/Company Name",
  "Your Name": "Your Name",
  "From what area? (insert coordinates if possible)":
    "From what area? (insert coordinates if possible)",
  "Size of Area": "Size of Area",
  "When do you need it?": "When do you need it?",
  "What’s your email? *": "What’s your email? *",
  "We’ve received your request!": "We’ve received your request!",
  "We’ll get back to you once we’ve processed your request":
    "We’ll get back to you once we’ve processed your request",
  "Save changes": "Save changes",
  "Active Missions": "Active Missions",
  "Unassigned Missions": "Unassigned Missions",
  "Mission Inquiries": "Mission Inquiries",
  "Completed Missions": "Completed Missions",
  "Name / ID": "Name / ID",
  Pilot: "Drone Operator",
  Location: "Location",
  Deadline: "Deadline",
  Client: "Client",
  "Date of Flight": "Date of Flight",
  Status: "Status",
  "From what area?": "From what area?",
  "What’s your email?": "What’s your email?",
  "Assign a Drone Operator to the Mission":
    "Assign a Drone Operator to the Mission",
  "Assign Mission to Drone Operator": "Assign Mission to Drone Operator",
  "Copy URL & Email to the Drone Operator":
    "Copy URL & Email to the Drone Operator",
  "Copy URL": "Copy Download URL",
  "You have no active missions": "You have no active missions",
  "You have no mission inquiries": "You have no mission inquiries",
  "There are no missions assigned to you.":
    "There are no missions assigned to you.",
  "Need help?": "Need help?",
  "Upload content from your flight": "Upload content from your flight",
  "Drag & drop your .zip .jpg or .tiff here or press the button below":
    "Drag & drop your .zip .jpg or .tiff here or press the button below",
  "Aerial imagery captured over Sweden might be subject to approval from Lantmäteriet prior to dissemination to other parties":
    "Aerial imagery captured over Sweden might be subject to approval from Lantmäteriet prior to dissemination to other parties",
  "Check your country's regulations regarding dissemination of drone data":
    "Check your country's regulations regarding dissemination of drone data",
  "Upload Content": "Upload Content",
  "Your files have been submitted!": "Your files have been submitted!",
  "You just helped us reduce air pollution by flying a drone. Not bad! Now your drone photos are off to do even more good for people & the planet!":
    "You just helped us reduce air pollution by flying a drone. Not bad! Now your drone photos are off to do even more good for people & the planet!",
  "Back to dashboard": "Back to dashboard",
  "Package is ready for Download & confirmation":
    "Package is ready for Download & confirmation",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet purus ut turpis consequat,":
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet purus ut turpis consequat,",
  "Download Package": "Download Package",
  "Submit For Client Download": "Submit For Client Download",
  "Send Download URL to Client": "Send Download URL to Client",
  "Copy and send the URL below to Client":
    "Copy and send the URL below to Client",
  "Edit the uploaded content by uploading a new file":
    "Edit the uploaded content by uploading a new file",
  Copied: "Download URL Copied",
  "Your package is ready for download": "Your package is ready for download",
  "We’ve processed the content and you can now download it by pressing the button below":
    "We’ve processed the content and you can now download it by pressing the button below",
  "Area to map (sq km)": "Area to map (sq km)",
};
